import Vue from "vue";
import { encrypt } from "./crypto";
import { getRange } from "./utils";

/**
 * Get target from query string
 */
let targetCodes = {
  ENT: "Entreprise",
  ASSO: "Association",
  COLL: "Collectivité",
};

const urlParams = new URLSearchParams(window.location.search);
let targetCode = urlParams.get("target");

if (targetCode && !targetCodes[targetCode]) {
  throw new Error("Type de cible non pris en charge");
}

let targetLabel = targetCodes[targetCode];

/**
 * Marchés
 */
let targets = [
  { value: "Entreprise", text: "Une entreprise", code: "ENT" },
  { value: "Association", text: "Une association", code: "ASSO" },
  {
    value: "Etablissement public",
    text: "Un établissement public",
    code: "COLL",
  },
  { value: "Commune", text: "Une commune", code: "COLL" },
  {
    value: "Etablissement public de coopération intercommunale",
    text: "Un établissement public de coopération intercommunale",
    code: "COLL",
  },
];

let cyberUp = {
  /**
   * Code et libellé cible
   */
  targetCode,
  targetLabel,
  /**
   * Liste des cibles de marché possible selon le paramètre de requête
   */
  targets: targetCode ? targets.filter((t) => t.code === targetCode) : targets,
  /**
   * Activité pour les types suivants
   */
  hasActivity(type) {
    return ["Entreprise", "Association", "Etablissement public"].includes(type);
  },
  /**
   * Chiffre d'affaire pour les types suivants
   */
  hasTurnover(type) {
    return ["Entreprise"].includes(type);
  },
  getRange,
  /**
   * Push DTM tracking
   */
  push({ step, title, type, postCode, data, common }) {
    let code = targetCode;

    if (type) {
      /**
       * Déduire le code marché selon la valeur
       */
      code = targets.find((t) => t.value === type).code;
    }

    window.wa_gfr = {
      contenu: {
        plateforme: "Useweb",
        version: "1.0.0",
        region_enregistree: "LBR",
        type_content: "Devis",
        marche: targetCodes[code],
        fil_ariane: `Mon assurance::Devis CyberUP LBR ${step} ${title}`,
        code_postal: postCode,
      },
      contact: {
        origine: "site marchand",
      },
      outils_an: {
        product_range: "CyberUP",
        identifiant_produit: "CyberUP",
        nom_etape: title,
        numero_etape: step,
      },
      devis: {
        cyberup: data,
      },
      commun: common
        ? {
            hash_email: encrypt(common.email),
            hash_phone: encrypt(common.phone),
          }
        : {},
    };

    if (window._satellite && window._satellite.track) {
      window._satellite.track(`devis_cyberup_e${step}`);
    }
  },
};

Vue.prototype.$cyberUp = cyberUp;
